/* Target the editor container */
.ql-editor {
  font-size: 16px;
  background-color: #fff;
  color: #0e0e0e;
  border-radius: 0px 0px 8px 8px;
}

/* .ql-container > .ql-editor.ql-blank::placeholder {
  color: red !important;
  font-size: 28px !important;
} */
.ql-container > .ql-editor.ql-blank::before {
  font-size: 14px !important;
}

.ql-container.ql-snow {
  height: 200px;
}

.ql-container {
  border-radius: 0px 0px 8px 8px;
}

.ql-toolbar {
  border-radius: 8px 8px 0px 0px;
  background-color: #fff;
  width: 100%;
  padding: 8px 0px !important;
  text-align: left;
  height: 43px;
}

@media (max-width: 400px) {
  .ql-toolbar {
    height: auto;
  }
  /* .ql-toolbar svg {
    height: 16px !important;
    width: 16px !important;
  } */
}

.textBlock p,
.textBlock ol li,
.textBlock ul li {
  text-align: left;
}

.textBlock ol li,
.textBlock ul li {
  text-align: left;
  list-style-position: inside;
}

.textBlock .ql-align-right,
.textBlock ol li.ql-align-right,
.textBlock ol li.ql-align-right::marker,
.textBlock ul li.ql-align-right,
.textBlock ul li.ql-align-right::marker {  
  text-align: right !important;
}

.textBlock ol li.ql-align-right::marker,
.textBlock ul li.ql-align-right::marker {
  text-align-last: right !important;
}

.textBlock .ql-align-left {
  text-align: left;
}

.textBlock .ql-align-center {
  text-align: center;
}

.textBlock p > span.ql-size-small,
.textBlock p > strong.ql-size-small {
  font-size: small;
}
.textBlock li > span.ql-size-small,
.textBlock li > strong.ql-size-small {
  font-size: small;
}
.textBlock p > span.ql-size-large,
.textBlock p > strong.ql-size-large {
  font-size: larger;
}
.textBlock li > span.ql-size-large,
.textBlock li > strong.ql-size-large {
  font-size: larger;
}
.textBlock p > span.ql-size-huge,
.textBlock p > strong.ql-size-huge {
  font-size: xx-large;
}
.textBlock li > span.ql-size-huge,
.textBlock li > strong.ql-size-huge {
  font-size: xx-large;
}

.textBlock li::marker {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center !important;
}

.quill .ql-toolbar .ql-formats {
  margin-right: 5px;
}

/* .quill .ql-toolbar .ql-formats:last-child {
  margin-left: 5px;
} */
