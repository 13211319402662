.ReactModal__Overlay {
    background: rgba(34, 36, 44, 0.2) !important;
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 2000;
  }
  
  .ReactModal__Overlay > div {
    border-radius: 20px 20px 0 0;
  }
  
  .ReactModal__Content--after-open {
    overflow-y: scroll !important;
    max-height: 80%;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  
  .ReactModal__Content--after-open::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  
  .react-modal-content {
    border-radius: 20px;
  }
  
  .ReactModal__Content:focus-visible {
    outline: none;
  }
  
  @media (min-width: 500px) {
    .ReactModal__Overlay {
      align-items: center;
    }
  
    .ReactModal__Overlay > div {
      border-radius: 20px;
    }
  
    .ReactModal__Content--after-open {
      max-height: 90%;
    }
  }
  