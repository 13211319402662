@import url('./styles/text.css');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}

html,
body {
  scroll-behavior: smooth;
  transition: all linear 0.5s;
}

input {
  font-size: 16px !important;
}

.strikethrough-text {
  text-decoration: line-through;
}
