#custom-input-wrapper,
#custom-input-password-wrapper,
#custom-select-wrapper,
#custom-phone-input-wrapper {
  height: 50px;
}

.input-field,
#input-password-field,
#input-select-field,
#input-addon {
  z-index: 2;
  height: 50px;
}

.select-option {
  padding: 3px;
}

.dark-select {
  color: #f1f1f1;
}

.light-select {
  color: #111111;
}
#input-phone-field {
  height: 50px;
}

input:-webkit-autofill {
  border: 0px solid #9a9b9e;
  border-radius: 8px;
  box-shadow: 0 0 0 50px #ffffff inset;
  -webkit-box-shadow: 0 0 0 50px #ffffff inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #16181dcf;
}

input.input-addon-field:-webkit-autofill,
input.input-addon-field:-webkit-autofill:hover,
input.input-addon-field:-webkit-autofill:focus {
  border: 0;
  border-color: transparent;
}

input:-webkit-autofill:hover {
  border: 0px solid #9a9b9e;
  border-radius: 8px;
  box-shadow: 0 0 0 50px #ffffff inset;
  -webkit-box-shadow: 0 0 0 50px #ffffff inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #16181dcf;
}

input:-webkit-autofill:focus {
  border: 0px solid #9a9b9e;
  border-radius: 8px;
  box-shadow: 0 0 0 50px #ffffff inset;
  -webkit-box-shadow: 0 0 0 50px #ffffff inset; /*your box-shadow*/
  -webkit-text-fill-color: #16181dcf;
}

#input-label,
#input-password-label {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  opacity: 50%;
  position: absolute;
  transform: translate(16px, 11px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  z-index: 1;
}

#input-addon-label {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  opacity: 50%;
  position: absolute;
  transform: translate(2px, 13.5px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  z-index: 1;
}

#input-color-label {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  opacity: 50%;
  position: absolute;
  left: 52px;
  transform: translate(18px, 18px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  z-index: 1;
}

#custom-input-wrapper:focus-within #input-label,
#custom-input-wrapper:focus-within #input-color-label,
#custom-input-password-wrapper:focus-within #input-password-label {
  transform: translate(15px, 2px) scale(0.6);
  z-index: 2;
}

#custom-input-wrapper:focus-within #input-addon-label {
  transform: translate(2px, 1px) scale(0.6);
  z-index: 2;
}

#custom-input-wrapper .Active,
#custom-input-password-wrapper .Active {
  transform: translate(15px, 2px) scale(0.6);
}

#custom-input-wrapper.custom-input-addon-wrapper .Active {
  transform: translate(2px, 1px) scale(0.6);
}

#custom-text-area-wrapper:focus-within #input-label,
#custom-text-area-wrapper:focus-within #input-color-label {
  transform: translate(15px, 2px) scale(0.6);
  z-index: 2;
}


#custom-text-area-wrapper:focus-within #input-addon-label {
  transform: translate(2px, 1px) scale(0.6);
  z-index: 2;
}

#custom-text-area-wrapper .Active {
  transform: translate(15px, 2px) scale(0.6);
}


#input-phone-label {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  opacity: 50%;
  position: absolute;
  transform: translate(65px, 15px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
}

#custom-phone-input-wrapper:focus-within #input-phone-label {
  transform: translate(65px, 2px) scale(0.8);
  z-index: 2;
}

#custom-phone-input-wrapper .Active {
  transform: translate(65px, 2px) scale(0.8);
}

.number-format-input:hover {
  border: 0;
}

.number-format-input:focus {
  border: 0.5px solid #9a9b9e !important;
}

.number-format-input:disabled {
  opacity: 0.9;
}

input.input-reg-field[type='email'],
input.input-addon-field {
  text-transform: lowercase;
}

.blank-input-field[type='text'] {
  font-size: 50px;
}

input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(50%);
}
